import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { fetchWithoutAuth } from "api_client";
import { MERGE_DEMO_PAGE } from "components/docs/navigation/links";
import { validateEmail } from "components/docs/utils/services";
import { CheckCircle, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

interface SubmitUseCaseRequest {
  name: string;
  email: string;
  feedback: string;
}

const SubmitUseCaseRequest = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [useCaseInformation, setUseCaseInformation] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const emailErrorMessage = "Please enter a valid email address.";
  const [emailError, setEmailError] = useState<boolean>(false);

  function SubmitUseCaseRequest(props: SubmitUseCaseRequest) {
    const { name, email, feedback } = props;
    fetchWithoutAuth({
      path: "/marketing/submit-use-case-request",
      method: "POST",
      body: {
        name: name,
        email: email,
        feedback: feedback,
      },
      onResponse: () => {},
    });
  }

  const needsInputs = name === "" || email === "" || useCaseInformation === "";

  const submitUseCaseRequest = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      SubmitUseCaseRequest({
        name: name,
        email: email,
        feedback: useCaseInformation,
      });
      setHasSubmitted(true);
    }
  };

  const openDemoLink = () => {
    window.open(`${MERGE_DEMO_PAGE}`, "_blank");
  };

  return (
    <div className="w-100 py-5 px-6 bg-sim-blue rounded-lg">
      {hasSubmitted ? (
        <div className="justify-content-between d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center">
            <CheckCircle className="text-teal-50 mr-2 flex-shrink-0" size={16} />{" "}
            <b>Thanks for your feedback! Your response will help us improve this page.</b>
          </div>
          <Button
            variant={ButtonVariant.PrimaryBlack}
            rightIcon={<ChevronRight size={12} />}
            size="sm"
            onClick={openDemoLink}
            className="ml-4"
          >
            Go to demo
          </Button>
        </div>
      ) : (
        <>
          <div className="d-flex flex-row mb-6 flex-wrap">
            <div className="font-semibold">Not seeing what you're looking for?&nbsp;</div>{" "}
            <div>We probably support it &mdash; request a use case below.</div>
          </div>
          <div className="mb-2">Contact information:</div>
          <div className="mb-3">
            <Form.Control
              className="shadow-md border-none"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <Form.Control
              className="border-none shadow-md"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={emailError}
            />
            {emailError && (
              <Form.Control.Feedback
                className="text-sm text-red-50 is-invalid-absolute"
                type="invalid"
              >
                {emailErrorMessage}
              </Form.Control.Feedback>
            )}
          </div>
          <div className="mb-2">Provide more information on your use case:</div>
          <div className="mb-4">
            <Form.Control
              className="border-none shadow-md"
              placeholder="What use case are you looking for?"
              value={useCaseInformation}
              onChange={(e) => setUseCaseInformation(e.target.value)}
            />
          </div>
          <Button
            variant={ButtonVariant.PrimaryBlack}
            size="sm"
            onClick={submitUseCaseRequest}
            disabled={needsInputs}
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default SubmitUseCaseRequest;
